"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer } from "react";
import { NostoContext } from "./NostoContext";
import { InitialState, NostoReducer } from "./NostoReducer";



export default function NostoProvider({ children }){
    const [ state, dispatch ] = useReducer(NostoReducer, InitialState);

    return (       
        <NostoContext.Provider value={[ state, dispatch ]}>
            {children}
        </NostoContext.Provider>
    );
};
