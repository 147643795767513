"use client"

import { useReducer, createContext } from 'react';
import { GlobalReducer, initialState } from './reducers/GlobalReducer';
import { ToastContainer } from 'react-toastify'
import { toastDefaults } from 'lib/Constants/toastDefaults';


export const GlobalContext = createContext();

// Create our Provider with our Reducer as our state manager
export const GlobalContextProvider = props => {
    const [state, dispatch] = useReducer(GlobalReducer, initialState);

    return (
        <GlobalContext.Provider value={[ state, dispatch ]}>
            {props.children}

            <ToastContainer stacked {...toastDefaults} />
        </GlobalContext.Provider>
    );
};

