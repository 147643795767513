import { GlobalContext } from 'contexts/GlobalContext'
import Script from 'next/script'
import { useContext, useEffect, useState } from 'react'


/***
 * Since our team wants to keep Zendesk we are going to do an long lazy load
 * of 10 seconds after the component mounts before we actually load
 * this wack Zendesk code. Zendesk severely affects our PageSpeed score
 */

export default function Zendesk({ nonce }){
    const [ global, dispatch ] = useContext(GlobalContext)
    const [ load, setLoad ] = useState(true)

    useEffect(() => {
        if( window.location.hostname !== "localhost" ){
            setTimeout(() => setLoad(true), 60000)
        }
        
    },[])

    return(
        <>
            {load && (
                <Script 
                    strategy="afterInteractive"
                    id="ze-snippet" 
                    nonce={nonce}
                    src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_zendesk_key_id}`}
                    onLoad={() => {
                        try{
                            window.zESettings = {
                                webWidget: {
                                    position: { 
                                        horizontal: "left",
                                        vertical: "bottom"
                                    },
                                    color: {
                                        launcherText: '#ffffff'
                                    }
                                }
                            }

                            dispatch({
                                type: "zendeskInit",
                                data: true
                            })

                        }catch(err){
                            console.log(err)
                        }

                    }} 
                />
            )}
        </>
    )
}